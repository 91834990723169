import { PropsWithChildren } from "react";
import Container from "react-bootstrap/Container";
import LanguageSwitcher from "../navigation/LanguageSwitcher";

export interface HeaderProps {
  fluid: boolean;
  title?: string;
}
export default function Header({
  fluid = false,
  title,
}: PropsWithChildren<HeaderProps>) {
  return (
    <header className="py-2 bg-primary text-white mb-3">
      <Container className="d-flex justify-content-between align-items-center">
        <h1>{title}</h1>
        {/* <LanguageSwitcher short/> */}
      </Container>
    </header>
  );
}
