import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export interface PriceFormaterProps {
  price: number | string;
}

export default function PriceFormatter({
  price,
}: PropsWithChildren<PriceFormaterProps>) {
  const { i18n } = useTranslation();
  const lang = i18n.language + "-CA";
  const formatter = new Intl.NumberFormat(lang, {
    style: "currency",
    currency: "CAD",
    maximumFractionDigits: 2,
  });

  const numberPrice = typeof price === 'string' ? parseInt(price) : price;

  return formatter.format(numberPrice) as unknown as JSX.Element;
}
