import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Button, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { FaUsers } from "react-icons/fa";
import { GiGearStick } from "react-icons/gi";
import { MdLocalGasStation } from "react-icons/md";
import VehicleImagesCarrousel from "../components/carrousel/VehicleImagesCarrousel";
import InvoiceModal from "../components/modals/InvoiceModal";
import SummaryAccessory from "../components/common/SummaryAccessory";
import SummaryInvoice from "../components/common/SummaryInvoice";
import { ConfigurationStateContext } from "../ConfigurationStateProvider";
import FormModal from "../components/modals/FormModal";

export default function VehicleSummary() {
  const { t } = useTranslation();
  const { parentUrl, make, model } = useParams();
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const handleShowFormModal = () => setShowFormModal(true);
  const handleCloseFormModal = () => setShowFormModal(false);
  const toggleInvoicePreview = () => {
    setInvoiceModal((invoicePreview) => !invoicePreview);
  };
  const exteriorImages = useSelector((state: any) => state.selectedColor);
  const iconContainerSize = {
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
  };
  function selectedPaymentMethod(event: any) {
    let method = event.target.value;
    setConfigurationState({
      ...configurationState,
      paymentMethod: method,
      incentiveOption: [],
      cashIncentiveOption: [],
      cumulatedCashIncentive: 0,
      cumulatedIncentiveTotal: 0,
    });
  }

//   function generateUrl() {
//     dispatch<any>(createBuildUrl(parentUrl, configurationState));
//   }

  if (!configurationState?.vehicleId)
    return <Navigate replace to={`/${parentUrl}/${make}/${model}`} />;

  return (
    <div>
      <Container>
        <div className="h1 mb-4">
          <Trans
            i18nKey={"summary.subtitlePrefix"}
            values={{
              make: configurationState?.make,
              trim: configurationState?.model?.toUpperCase(),
            }}
          />
        </div>
        <p className="h5 text-muted mb-4">
          <Trans
            i18nKey={"summary.intro"}
            values={{ make: configurationState?.make }}
          />
        </p>
        <Row>
          <Col lg="8">
            <VehicleImagesCarrousel img={exteriorImages} />
          </Col>
          <Col lg="4">
            <div className="h5 mb-4">
              {configurationState?.modele?.toUpperCase()}{" "}
              {configurationState?.trim?.toUpperCase()}{" "}
              {configurationState?.year}
            </div>
            <p className="mb-4">{configurationState?.transmission}</p>
            <div>
              <span className="me-4">
                <FaUsers className="text-muted fs-4 me-2" /> {configurationState.seat}
              </span>
              <span className="">
                <MdLocalGasStation className="text-muted fs-4 me-2"></MdLocalGasStation>
                {configurationState?.fuelEcon} L / 100km{" "}
                {/* {t("trim.fuelEconomy_suffix")} */}
              </span>
            </div>

            <div className="mt-5">
              <Button
                variant="primary"
                onClick={toggleInvoicePreview}
                className="w-100 mb-3"
              >
                {t("summary.paymentOptionsBtn")}
              </Button>
              <InvoiceModal
                open={invoiceModal}
                toggleOpen={toggleInvoicePreview}
              />
              {/* <Link
                to="/protection"
                className="btn btn-outline-dark w-100 mb-3"
              >
                {t("summary.protection_plans_btn")}
              </Link> */}
            </div>
          </Col>
        </Row>

        <Row className="mt-5" xs="1" lg="2">
          <Col>
            <div className="cartSection__title">
              {t("summary.optionSection")}
            </div>
            <div className="p-3">
              <Row xs="2" md="3" className="g-2">
                <Col className="d-flex align-items-center mb-3">
                  <div
                    className="me-2 text-center flex-shrink-0"
                    style={iconContainerSize}
                  >
                    <div
                      className="color-display"
                      style={{
                        backgroundColor: `#${configurationState?.exteriorOption}`,
                      }}
                    ></div>
                  </div>
                  <div>{t('summary.extColor')}</div>
                </Col>
                {/* <Col className="d-flex align-items-center mb-3">
                  <div
                    className="me-2 text-center flex-shrink-0"
                    style={iconContainerSize}
                  >
                    <div
                      className="color-display"
                      style={{ backgroundColor: "black" }}
                    ></div>
                  </div>
                  <div>{t('summary.intColor')}</div>
                </Col> */}

                <Col className="d-flex align-items-center mb-3">
                  {configurationState?.wheelOptions ? (
                    <div>
                      <div
                        className="me-2 text-center flex-shrink-0"
                        style={iconContainerSize}
                      >
                        <img
                          className="w-100 align-baseline"
                          src={configurationState?.wheelOption[2]}
                          alt="pneu"
                        />
                      </div>
                      <div>{configurationState?.wheelOption[1]}</div>
                    </div>
                  ) : (
                    <div>{t('wheel.default')}</div>
                  )}
                </Col>
                <Col className="d-flex align-items-center mb-3">
                  <div
                    className="me-2 text-center flex-shrink-0"
                    style={iconContainerSize}
                  >
                    <MdLocalGasStation className="align-baseline" />
                  </div>
                  <div>{configurationState?.fuelType}</div>
                </Col>
                <Col className="d-flex align-items-center mb-3">
                  <div
                    className="me-2 text-center flex-shrink-0"
                    style={iconContainerSize}
                  >
                    <GiGearStick className="align-baseline" />
                  </div>
                  <div>{configurationState?.transmission}</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className="cartSection__title">
              {t("summary.accessorySection")}
            </div>
            <div className="p-3">
              <Row xs="3" md="4" xl="5" className="g-2">
                {configurationState?.groupAccessoryOption.map(
                  (groupAccessory: any, i: any) => (
                    <Col key={i} className="d-flex align-items-center mb-3">
                      <SummaryAccessory accessory={groupAccessory} />
                    </Col>
                  )
                )}
                {configurationState?.accessoryOption.map(
                  (accessory: any, i: any) => (
                    <Col key={i} className="d-flex align-items-center mb-3">
                      <SummaryAccessory accessory={accessory} />
                    </Col>
                  )
                )}
                {configurationState?.interiorAccessoryOption.map(
                  (interiorAccessory: any, i: any) => (
                    <Col key={i} className="d-flex align-items-center mb-3">
                      <SummaryAccessory accessory={interiorAccessory} />
                    </Col>
                  )
                )}
              </Row>
            </div>
          </Col>
          {/* <Col>
            <div className="cartSection__title">
              {t("summary.saveShareSection")}
            </div>
            <div className="p-3">
              <Row xs="2">
                <Col
                  className="d-flex align-items-center"
                  onClick={generateUrl}
                >
                  <MdContentCopy className="me-3 fs-4"></MdContentCopy>
                  <span>{t("summary.copyLink")}</span>
                </Col>
                <Col xs="12">
                  <hr />
                </Col>
                <Col className="d-flex align-items-center">
                  <CgPrinter className="me-3 fs-4"></CgPrinter>
                  <span>{t("summary.download")}</span>
                </Col>
              </Row>
            </div>
          </Col> */}
        </Row>
        <div>
          <div className="cartSection__title">
            {t("summary.paymentMethodSection")}
          </div>
          <Form.Select
            className="mb-5 mt-3"
            style={{ maxWidth: 400 }}
            onChange={(e) => selectedPaymentMethod(e)}
            value={configurationState?.paymentMethod}
          >
            <option value="loan">{t("invoice.financing")}</option>
            <option value="lease">{t("invoice.location")}</option>
            <option value="cash">{t("invoice.cash")}</option>
          </Form.Select>
          <SummaryInvoice />
        </div>
        <div className="my-5">
          <Row className="g-3 justify-content-end" xs={1} sm={2} md={3} lg={4}>
            <Col>
              {/* <Button
                variant="secondary"
                className="w-100"
                style={{ maxWidth: 400 }}
              >
                {t("summary.testDriveBtn")}
              </Button> */}
            </Col>
            <Col>
              <Button
                variant="primary"
                className="w-100"
                style={{ maxWidth: 400 }}
                onClick={handleShowFormModal}
              >
                {t("summary.contactBtn")}
              </Button>
            </Col>
          </Row>
        </div>
        <FormModal
          showModal={showFormModal}
          handleCloseModal={handleCloseFormModal}
        />
      </Container>
    </div>
  );
}
