import { useState, useContext, PropsWithChildren } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import { createLead } from "../../actions";

export interface FormModalProps {
  showModal: any;
  handleCloseModal: any;
}

export default function FormModal({
  showModal,
  handleCloseModal,
}: PropsWithChildren<FormModalProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { parentUrl, make } = useParams();
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );
  const [formError, setFormError] = useState<string>("");
  const [formSending, setFormSending] = useState<boolean>(false);
  const handleChange = (e: any) => {
    setConfigurationState((prevForm: any) => ({
      ...prevForm,
      [e.target.name]:
        e.target.type !== "checkbox" ? e.target.value : e.target.checked,
    }));
  };

  const handleSubmit = (parentUrl: any, e: any, navigate: any) => {
    e.preventDefault();
    localStorage.setItem('builderRoot', `/${parentUrl}/${make}`);

    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (configurationState.phone === "") {
      setFormError(t("formModal.invalidPhone"));
    }
    if (configurationState.email === "") {
      setFormError(t("formModal.invalidEmail"));
    }
    if (
      configurationState.email !== "" &&
      regex.test(configurationState.email) === false
    ) {
      setFormError(t("formModal.invalidEmail"));
    }
    if (configurationState.fullname === "") {
      setFormError(t("formModal.invalidName"));
    }

    if (
      configurationState.fullname !== "" &&
      configurationState.email !== "" &&
      regex.test(configurationState.email) === true &&
      configurationState.phone !== ""
    ) {
      setFormSending(true);
      dispatch<any>(createLead(parentUrl, configurationState, navigate));
    }
  };

  return (
    <Modal centered show={showModal} onHide={handleCloseModal}>
      <Form onSubmit={(e) => handleSubmit(parentUrl, e, navigate)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {configurationState.make} {configurationState.model}{" "}
            {configurationState.trim} {configurationState.year}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="fullname">
            <Form.Label>
              <>{t("formModal.fullname")}*</>
            </Form.Label>
            <Form.Control
              name="fullname"
              type="text"
              onChange={(e) => handleChange(e)}
              value={configurationState?.fullname}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>{t("formModal.email")}</Form.Label>
            <Form.Control
              name="email"
              type="text"
              onChange={(e) => handleChange(e)}
              value={configurationState?.email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>{t("formModal.phone")}</Form.Label>
            <Form.Control
              name="phone"
              type="text"
              onChange={(e) => handleChange(e)}
              value={configurationState?.phone}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="comment">
            <Form.Label>{t("formModal.comments")}</Form.Label>
            <Form.Control
              name="comment"
              as="textarea"
              rows={3}
              onChange={(e) => handleChange(e)}
              value={configurationState?.comment}
            />
          </Form.Group>
          {/* TODO: add a var for a different make in checkbox translate */}
          <Form.Check
            label={t("formModal.creditReport")}
            id="creditReport"
            name="creditReport"
            onChange={(e) => handleChange(e)}
            checked={configurationState?.creditReport}
          />
          <input type="hidden" name="data" value={""} />
        </Modal.Body>
        {formError && <div>{formError}</div>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("formModal.close")}
          </Button>
          <Button variant="primary" type="submit" disabled={formSending}>
            {t("formModal.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
