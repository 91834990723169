import { PropsWithChildren } from "react";
import { Button, Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUsers } from "react-icons/fa";
import { MdLocalGasStation } from "react-icons/md";
import PriceFormatter from "../../helpers/PriceFormater";

export interface TrimFeaturesModalProps {
  showModal?: boolean;
  handleCloseModal?: () => void;
  img?: string;
  model?: string;
  year?: string | number;
  trim?: string;
  price?: string | number;
  seats?: string | number;
  fuelLow?: string | number;
  fuelUnit?: string;
  accessories?: any;
}

export default function TrimFeaturesModal({
  showModal,
  handleCloseModal,
  img,
  model,
  year,
  trim,
  price,
  seats,
  fuelLow,
  fuelUnit,
  accessories,
}: PropsWithChildren<TrimFeaturesModalProps>) {
  const { t } = useTranslation()
  return (
    <Modal
      fullscreen="md-down"
      centered
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('featuresModals.principalFeatures')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="6">
            <Card.Img className="ratio-4-3" src={img} />
          </Col>
          <Col sm="6">
            <div>
              <div className="h5 mb-0 text-muted">
                {model} {year}{" "}
              </div>
              <div className="h5">{trim}</div>
            </div>
            {price ? 
                <div className="text-primary mb-2 h5"><PriceFormatter price={price}/></div>
            : null}
            <div className="mb-4">
              <span className="me-4">
                <FaUsers className="text-muted fs-4 me-2" />
                {seats}
              </span>
              {fuelLow && (
                <span className="text-nowrap">
                  <MdLocalGasStation className="text-muted fs-4 me-2" />
                  {fuelLow}
                  {fuelUnit}
                  km/l
                </span>
              )}
            </div>
          </Col>
        </Row>
        <ListGroup style={{ maxHeight: "40vh", overflow: "auto" }}>
          {accessories?.map((accessorie: any) => (
            <ListGroup.Item key={accessorie?.definition?.category?.id}>
              <div>{accessorie.definition?.category?._}</div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          {t('featuresModals.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
