import { useState, useContext, PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FaUsers } from "react-icons/fa";
import { MdLocalGasStation } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import TrimFeaturesModal from "../modals/TrimFeaturesModal";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import PriceFormatter from "../../helpers/PriceFormater";

export interface Category {
  id?: string | number;
  description?: string;
}

export interface Accessories {
  definition?: Category[];
}

export interface VehicleTrimCardProps {
  id?: string | number;
  img?: string;
  trimId: string | number;
  make?: string;
  model?: string;
  trim?: string;
  year?: string | number;
  engine?: string;
  price?: string | number;
  basePrice?: number;
  preparationFee?: number;
  transmission?: string;
  seats?: string | number;
  doors?: string | number;
  fuelType?: string;
  fuelLow?: string | number;
  fuelUnit?: string;
  baseColor?: string;
  baseColorName?: string;
  makeSlug?: string;
  modelSlug?: string;
  trimSlug?: string;
  description?: Accessories[];
}

export default function VehicleTrimCard({
  id,
  img,
  trimId,
  make,
  model,
  trim,
  year,
  engine,
  price,
  basePrice,
  preparationFee,
  transmission,
  seats,
  doors,
  fuelType,
  fuelLow,
  fuelUnit,
  baseColor,
  baseColorName,
  makeSlug,
  modelSlug,
  trimSlug,
  description,
}: PropsWithChildren<VehicleTrimCardProps>) {
  const { t } = useTranslation();
  const { parentUrl } = useParams();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );

  return (
    <>
      <Col md="6" lg="4">
        <Card className="h-100">
          <Card.Body className="d-flex flex-column p-4">
            <Card.Img className="ratio-4-3" src={img} />
            <Card.Title className="mb-0 text-muted">
              {model} {year}
            </Card.Title>
            <Card.Title>{trim}</Card.Title>
            <Card.Text className="text-uppercase mb-0">
              {t("vehiclePage.allIncluded")}
            </Card.Text>
            {price ? 
                <Card.Title className="text-primary mb-2"><PriceFormatter price={price}/></Card.Title> 
            : null}
            <div className="mb-4">
              <span className="me-4">
                <FaUsers className="text-muted fs-4 me-2" />
                {seats}
              </span>
              {fuelLow && (
                <span className="text-nowrap">
                  <MdLocalGasStation className="text-muted fs-4 me-2" />
                  {fuelLow} {t("vehiclePage.kmOnRoad")}
                </span>
              )}
            </div>
            <div className="mt-auto d-flex flex-column">
              <Button
                variant="outline-primary"
                className="text-uppercase mb-2"
                onClick={handleShowModal}
              >
                {t("vehiclePage.features")}
              </Button>
              <Link
                to={`/${parentUrl}/${makeSlug}/${modelSlug}/${trimSlug}`}
                className="btn btn-primary text-uppercase"
                onClick={() => {
                  setConfigurationState({
                    ...configurationState,
                    vehicleId: trimId,
                    make: make,
                    model: model,
                    trim: trim,
                    year: year,
                    engine: engine,
                    seat: seats,
                    transmission: transmission,
                    fuelEcon: fuelLow,
                    fuelType: fuelType,
                    msrp: basePrice,
                    prepFee: preparationFee,
                    exteriorOption: baseColor,
                    exteriorDescription: baseColorName,
                  });
                }}
              >
                {t("vehiclePage.buildAndPrice")}
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <TrimFeaturesModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        model={model}
        img={img}
        price={price}
        seats={seats}
        fuelLow={fuelLow}
        accessories={description}
      />
    </>
  );
}
