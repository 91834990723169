import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTrim } from "../actions/index";
import VehicleTrimCard from "../components/cards/VehicleTrimCard";
import PageTitle from "../components/common/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { ConfigurationStateContext, initialConfiguration } from "../ConfigurationStateProvider";

export interface ExteriorImage {
  color?: string;
  img_url: string;
}

export interface City {
  high?: string | number;
  low?: string | number;
}

export interface Highway {
  high?: string | number;
  low?: string | number;
}

export interface Fuel {
  city?: City;
  hwy?: Highway;
  unit?: string;
}

export interface TrimVehicle {
  id: string | number;
  trimId: string | number;
  make?: string;
  model?: string;
  trim?: string;
  year?: string | number;
  engine?: string;
  price?: number;
  basePrice?: number;
  preparationFee?: number;
  seats?: string | number;
  doors?: string | number;
  fuelType?: string;
  fuelEconomy: Fuel;
  baseColor?: string;
  baseColorName?: string;
  transmission?: string;
  exteriorImages: Array<ExteriorImage>;
  oemAccessories: any;
  makeSlug?: string;
  modelSlug?: string;
  trimSlug?: string;
}

export default function VehiclesTrimPage() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { parentUrl, make, model } = useParams();
  const lang = i18n.language;
  // eslint-disable-next-line
  const [_, setConfigurationState] = useContext(ConfigurationStateContext);

  useEffect(() => {
    dispatch<any>(getTrim(parentUrl, model, lang));
  }, [dispatch, parentUrl, lang, model]);
  const vehicles = useSelector((state: any) => state.vehicles);
  
  // reset selected options on load 
  useEffect(() => {
    setConfigurationState(initialConfiguration);
  }, [setConfigurationState]);

  // the price returned by the api does not include the Air conditioner tax...
  const getRealPrice = (price:number) => price + 15

  return (
    <Container>
      <PageTitle titleOne={make} titleTwo={model} />
      {vehicles[0]?.modelSlug === model && (
        <Row className="g-3 g-sm-5">
          {vehicles.map((e: TrimVehicle) => (
            <VehicleTrimCard
              key={e?.id}
              id={e?.id}
              trimId={e?.trimId}
              make={e?.make}
              model={e?.model}
              trim={e?.trim}
              year={e.year}
              engine={e?.engine}
              price={getRealPrice(e.price||0)}
              basePrice={e.basePrice}
              preparationFee={e.preparationFee}
              transmission={e.transmission}
              img={e?.exteriorImages[0]?.img_url}
              seats={e?.seats}
              fuelLow={e?.fuelEconomy?.city?.low}
              fuelType={e.fuelType}
              baseColor={e.baseColor}
              baseColorName={e.baseColorName}
              description={e.oemAccessories}
              makeSlug={e?.makeSlug}
              modelSlug={e?.modelSlug}
              trimSlug={e?.trimSlug}
            />
          ))}
        </Row>
      )}
    </Container>
  );
}
