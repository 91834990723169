import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import InvoiceModal from "../components/modals/InvoiceModal";
import PriceFormatter from "../helpers/PriceFormater";
import { ConfigurationStateContext } from "../ConfigurationStateProvider";
import {
  getEstimatedLeaseMonthlyPayment,
  getEstimatedLoanMonthlyPayment,
  vehiclePrice,
} from "../helpers/PaymentCalculatorHelper";

export default function Footer() {
  const { t } = useTranslation();
  const [invoicePreview, setInvoicePreview] = useState(false);
  const selectedColor = useSelector((state: any) => state.selectedColor);
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );
  const toggleInvoicePreview = () =>
    setInvoicePreview((invoicePreview) => !invoicePreview);

  function selectedPaymentMethod(event: any) {
    let method = event.target.value;
    setConfigurationState({
      ...configurationState,
      paymentMethod: method,
      incentiveOption: [],
      cashIncentiveOption: [],
      cumulatedCashIncentive: 0,
      cumulatedIncentiveTotal: 0,
    });
  }

  function getInvoiceTotalPrice(configurationState: any) {
    let total =
      configurationState.msrp +
      configurationState.acFee +
      configurationState.tiresFee +
      configurationState.prepFee +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice;
    return total;
  }
  return (
    <div className={`invoice app__footer`}>
      <Container>
        <div className="position-relative">
          <FaChevronUp
            role="button"
            className="invoice__chevron"
            onClick={toggleInvoicePreview}
          /> 
        </div>
        <Row className="align-items-center">
          <Col lg="6" className="d-none d-lg-block position-relative">
            {selectedColor.length ? (
              <img
                className="invoice__img"
                src={selectedColor[0]?.img_url}
                alt={`${configurationState.make} ${configurationState.model} ${configurationState.trim}`}
              />
            ) : (
              ""
            )}
            <div className="text-uppercase">
              {configurationState.modele} {configurationState.trim}{" "}
              {configurationState.year}
            </div>
            <div className="small">
              {t("invoice.basePrice")}:{" "}
              <span className="text-nowrap">
                <PriceFormatter price={vehiclePrice(configurationState)} />
              </span>
            </div>
          </Col>
          <Col xs="6" lg="3">
            <Form.Select
              className="invoice__btn my-3"
              onChange={(e) => selectedPaymentMethod(e)}
              value={configurationState.paymentMethod}
            >
              <option value="loan">{t("invoice.financing")}</option>
              <option value="lease">{t("invoice.location")}</option>
              <option value="cash">{t("invoice.cash")}</option>
            </Form.Select>
          </Col>
          <Col xs="6" lg={"3"}>
            <div className="invoice__pricing">
              <div>
                {t("invoice.totalLabel")}:{" "}
                <span className="text-nowrap">
                  <PriceFormatter
                    price={getInvoiceTotalPrice(configurationState)}
                  />
                </span>
              </div>
              {configurationState.paymentMethod !== "cash" && (
                <div>
                  {configurationState.paymentMethod === "lease" ? (
                    <PriceFormatter
                      price={getEstimatedLeaseMonthlyPayment(
                        configurationState
                      )}
                    />
                  ) : (
                    <PriceFormatter
                      price={getEstimatedLoanMonthlyPayment(configurationState)}
                    />
                  )}
                  <> / {t("invoice.month")}</>
                </div>
              )}
              {configurationState.paymentMethod !== "cash" ? (
                <div className="small">
                  {configurationState.paymentMethod === "loan"
                    ? configurationState.loanDuration
                    : configurationState.leaseDuration}{" "}
                  {t("invoice.month")} @{" "}
                  {configurationState.paymentMethod === "loan"
                    ? configurationState.loanRates.toFixed(2)
                    : configurationState.leaseRates.toFixed(2)}{" "}
                  %
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <InvoiceModal open={invoicePreview} toggleOpen={toggleInvoicePreview} />
      </Container>
    </div>
  );
}
