import { useRef, useState, useEffect, useContext } from "react";
import { Form, Overlay, Tooltip } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import PriceFormatter from "../../helpers/PriceFormater";

export default function InvoiceDiscount(rebate: any, id: any) {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleTooltip = () => setShowTooltip(!showTooltip);
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );

  const ref = useRef(null);

  function incentiveTotal(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      setConfigurationState({
        ...configurationState,
        incentiveOption: [
          ...configurationState.incentiveOption,
          [event.target.value, event.target.attributes[0].value],
        ],
        cashIncentiveOption: [
          ...configurationState.cashIncentiveOption,
          event.target.value,
        ],
      });
    } else {
      const index = configurationState.cashIncentiveOption.indexOf(
        event.target.value
      );
      configurationState.incentiveOption.splice(index, 1);
      configurationState.cashIncentiveOption.splice(index, 1);
      setConfigurationState({
        ...configurationState,
        incentiveOption: [...configurationState.incentiveOption],
        cashIncentiveOption: [...configurationState.cashIncentiveOption],
      });
    }
  }

  const cumulatedIncentivePrice = () => {
    let counters = [...configurationState.cashIncentiveOption];
    let total = 0;
    counters.forEach(function (c) {
      let e = parseInt(c);
      total += e;
    });

    return total;
  };

  let totalIncentive = cumulatedIncentivePrice();

  useEffect(() => {
    if (totalIncentive >= 0) {
      setConfigurationState((e: any) => ({
        ...e,
        cumulatedIncentiveTotal: totalIncentive,
      }));
    }
  }, [setConfigurationState, totalIncentive]);

  function handleChecked(i: any) {
    let incentiveArray = [...configurationState.incentiveOption];

    let checked = false;
    incentiveArray.forEach(function (c) {
      if (c[1] === i) {
        checked = true;
      }
    });
    return checked;
  }

  return (
    <div className="d-flex align-items-center">
      <Form.Check
        id={id}
        inline
        label={rebate.rebate.programDescription}
        data-value={rebate.rebate.programDescription}
        value={
          rebate?.rebate?.programValues?.valueVariations[0]?.programValues[0]
            ?.cash
        }
        onChange={incentiveTotal}
        checked={handleChecked(rebate.rebate.programDescription)}
      />
      {/* TODO: set unique id based on props*/}
      <button ref={ref} onClick={handleTooltip} className="reset-btn d-flex align-items-center">
        <span className="me-1">
            <PriceFormatter price={rebate?.rebate?.programValues?.valueVariations[0]?.programValues[0]?.cash}/>
        </span>
        <BsInfoCircle />
      </button>
      <Overlay target={ref.current} show={showTooltip} placement="right">
        <Tooltip>{rebate?.rebate?.programRules[0]?.description}</Tooltip>
      </Overlay>
    </div>
  );
}
