import { useEffect, useState, useContext } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicle,
  getSelectedColor,
  getInventoryVehicle,
} from "../actions/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PriceFormatter from "../helpers/PriceFormater";
import VehicleImagesCarrousel from "../components/carrousel/VehicleImagesCarrousel";
import ExteriorColorOptions from "../components/accordion/ExteriorColorOptionsAccordion";
import WheelOptionsAccordion from "../components/accordion/WheelOptionsAccordion";
import AccesoriesOptionsAccordion from "../components/accordion/AccessoriesOptionsAccordion";
import InteriorAccessoriesOptionsAccordion from "../components/accordion/InteriorOptionsAccordion";
import GroupAccesoriesOptionsAccordion from "../components/accordion/GroupOptionsAccordion";
import AvailableVehiclesCard from "../components/cards/AvailableVehiclesCard";
import Switch from "../components/common/Switch";
import { ConfigurationStateContext } from "../ConfigurationStateProvider";
import { Accordion } from "react-bootstrap";
import { vehiclePrice } from "../helpers/PaymentCalculatorHelper";

export default function VehiclePage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { parentUrl, make, model, trim } = useParams();
  const lang = i18n.language;
  const [interiorSlider, setInteriorSlider] = useState(false);
  const [configurationState] = useContext(ConfigurationStateContext);
  const [vehicles] = useSelector((state: any) => state.vehicle);
  const exteriorImages = useSelector((state: any) => state.selectedColor);
  const inventoryVehicles = useSelector((state: any) => state.inventoryVehicle);

  useEffect(() => {
    dispatch<any>(getVehicle(parentUrl, model, trim, lang));
  }, [dispatch, parentUrl, lang, model, trim]);

  useEffect(() => {
    dispatch<any>(
      getSelectedColor(
        parentUrl,
        model,
        trim,
        configurationState?.exteriorOption
      )
    );
  }, [dispatch, parentUrl, model, trim, configurationState?.exteriorOption]);

  useEffect(() => {
    dispatch<any>(getInventoryVehicle(parentUrl));
  }, [dispatch, parentUrl]);

  function totalOptionPrice(configurationState: any) {
    let total =
      configurationState.interiorOptionPrice +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice;

    return total;
  }

  if (!configurationState?.vehicleId)
    return <Navigate replace to={`/${parentUrl}/${make}/${model}`} />;

  if (!vehicles || vehicles.trimSlug !== trim) return <></>;

  return (
    <Container>
      <Row>
        <Col lg="8" className="sticky-top bg-white align-self-start">
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div className="h1 mb-0">
              {vehicles?.modele} {vehicles?.trim} {vehicles?.year}
            </div>
            <div className="ms-auto ps-3 text-end">
              <span className="fs-1">
                <PriceFormatter price={vehiclePrice(configurationState)} />
              </span>
              <Switch
                className="ms-3"
                toggle={() =>
                  setInteriorSlider((interiorSlider) => !interiorSlider)
                }
                selected={interiorSlider}
              />
            </div>
          </div>
          <div className="swap">
            <VehicleImagesCarrousel
              img={exteriorImages}
              className={`${!interiorSlider ? "swap__in" : ""}`}
            />
            <VehicleImagesCarrousel
              img={vehicles?.interiorImages}
              className={`${interiorSlider ? "swap__in" : ""}`}
            />
          </div>
        </Col>
        <Col lg="4">
          <div className="text-end">
            <span>{t("vehiclePage.totalOptionPrice")}</span>
            <span className="fs-1 ms-3">
              <PriceFormatter price={totalOptionPrice(configurationState)} />
            </span>
          </div>

          <Accordion
            defaultActiveKey={["0", "1", "2", "3", "4"]}
            alwaysOpen
            className="vehicleAccordion"
          >
            <ExteriorColorOptions
              eventKey={"0"}
              title={t("vehiclePage.exteriorPicker")}
              exteriorColors={vehicles?.baseColor}
            />
            {vehicles?.wheelsOptions && (
              <WheelOptionsAccordion
                eventKey={"1"}
                title={t("vehiclePage.wheelPicker")}
                wheelOptions={vehicles?.wheelsOptions}
              />
            )}
            {/* <InteriorOptions vehicle={vehicle} /> */}
            {/* {!configurationState?.transmission ? (
              ""
            ) : (
              <TransmissionOptions transmission={configurationState} />
            )} */}
            {vehicles?.groupOptions.length ? (
              <GroupAccesoriesOptionsAccordion
                eventKey={"2"}
                title={t("vehiclePage.groupAccessoriesPicker")}
                groupAccessoryOptions={vehicles?.groupOptions}
              />
            ) : null}
            {vehicles?.accessoriesOptions.length ? (
              <AccesoriesOptionsAccordion
                eventKey={"3"}
                title={t("vehiclePage.accessoriesPicker")}
                accessoryOptions={vehicles?.accessoriesOptions}
              />
            ): null}
            {vehicles?.interiorOptions.length ? (
              <InteriorAccessoriesOptionsAccordion
                eventKey={"4"}
                title={t("vehiclePage.interiorAccessoriesPicker")}
                interiorAccessoryOptions={vehicles?.interiorOptions}
              />
            ): null}
          </Accordion>

          {/* {inventoryVehicles?.length > 0 && (
            <>
              {inventoryVehicles.map((inventoryVehicle: any) => (
                <AvailableVehiclesCard
                  key={inventoryVehicle.id}
                  model={inventoryVehicle.modele}
                  year={inventoryVehicle.year}
                  basePrice={inventoryVehicle.base_price}
                  img={inventoryVehicle.images[0].path}
                  link={inventoryVehicle.vehiclelink}
                />
              ))}
            </>
          )} */}

          <div className="my-4 d-flex flex-column">
            {/* <Link className="btn btn-outline-primary mb-2" to="/protection">
              Voir les protections
            </Link> */}
            <Link
              className="btn btn-primary"
              to={`/${parentUrl}/${make}/${model}/${trim}/summary`}
              // onClick={setVdpConfigurationState}
            >
              {t("vehiclePage.paymentBtn")}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
