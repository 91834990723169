import { useState, useContext, Fragment } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import PriceFormatter from "../../helpers/PriceFormater";
import { BsChevronDown } from "react-icons/bs";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import {
  totalVehiclePrice,
  vehiclePrice,
  totalAccessoryPrice,
  taxeTps,
  taxeTvq,
  vehicleFinalTotalPrice,
  getSummaryTotalCredit,
  getSummaryTotalIncentive,
} from "../../helpers/PaymentCalculatorHelper";
import { useTranslation } from "react-i18next";

export default function SummaryInvoice() {
  const [openVehiclePrice, setOpenVehiclePrice] = useState(false);
  const [openOptionPrice, setOpenOptionPrice] = useState(false);
  const [openCreditPrice, setOpenCreditPrice] = useState(false);
  const [configurationState] = useContext(ConfigurationStateContext);
  const { t } = useTranslation()

  let tps = taxeTps(configurationState);
  let tvq = taxeTvq(configurationState);

  const allAccessories = [...configurationState.groupAccessoryOption, ...configurationState.accessoryOption, ...configurationState.interiorAccessoryOption]

  return (
    <div className="cartInvoice">
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">{t('summary.trim')}</div>
        <div className="cartInvoice__option">
          {configurationState?.modele?.toUpperCase()}{" "}
          {configurationState?.trim?.toUpperCase()}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={vehiclePrice(configurationState)} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">{t('summary.engine')}</div>
        <div className="cartInvoice__option">
          {configurationState?.engine.toUpperCase()}
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">{t('summary.color')}</div>
        <div className="cartInvoice__option">
          {configurationState?.exteriorDescription}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.exteriorOptionPrice} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">{t('summary.wheel')}</div>
        <div className="cartInvoice__option">
          {configurationState?.wheelOption[1]}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.wheelOptionPrice} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">{t('summary.accessories')}</div>
        <div className="cartInvoice__option">
            {allAccessories.map(
                (accessory: any, i: any) => 
                    <Fragment key={i}>{accessory[1]} { allAccessories.length-1 !== i ? ', ' : ''}</Fragment>
            )}
        </div>
        <div className="cartInvoice__price ">
            <PriceFormatter price={totalAccessoryPrice(configurationState)} />
        </div>
      </div>
      {/* <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Intérieur</div>
        <div className="cartInvoice__option">
          {configurationState?.interiorOption}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={0} />
        </div>
      </div> */}
      <div className="">
        <div className="h5 mb-3">{t('summary.recapPrice')}</div>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenVehiclePrice(!openVehiclePrice)}
          >
            {t('invoice.vehiclePrice')}{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openVehiclePrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={vehiclePrice(configurationState)} />
          </div>
        </div>
        <Collapse in={openVehiclePrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">MSRP</div>
                <div>
                  <PriceFormatter price={configurationState?.msrp} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.transportFees')}</div>
                <div>
                  <PriceFormatter price={configurationState?.prepFee} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.airConditionnerTax')}</div>
                <div>
                  <PriceFormatter price={configurationState?.acFee} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.airConditionnerTax')}</div>
                <div>
                  <PriceFormatter price={configurationState?.tiresFee} />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenOptionPrice(!openOptionPrice)}
          >
            {t('summary.optionsPrice')}{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openOptionPrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={totalAccessoryPrice(configurationState)} />
          </div>
        </div>
        <Collapse in={openOptionPrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('summary.wheel')}</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.wheelOptionPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('summary.extColor')}</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.exteriorOptionPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('summary.extAccessories')}</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.accessoryOptionTotalPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('summary.intAccessories')}</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.interiorAccessoryOptionTotalPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('summary.groupAccessories')}</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.groupAccessoryOptionTotalPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenCreditPrice(!openCreditPrice)}
          >
            Credit{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openCreditPrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={getSummaryTotalCredit(configurationState)} />
          </div>
        </div>
        <Collapse in={openCreditPrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.tradeIn')}</div>
                <div>
                  <PriceFormatter price={configurationState?.tradeIn} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.downPayment')}</div>
                <div>
                  <PriceFormatter price={configurationState?.downPayment} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">{t('invoice.incentive')}</div>
                <div>
                  <PriceFormatter
                    price={getSummaryTotalIncentive(configurationState)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div className="">TPS</div>
          <div className="price_fs">
            <PriceFormatter price={taxeTps(configurationState)} />
          </div>
        </div>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div className="">TVQ</div>
          <div className="price_fs">
            <PriceFormatter price={taxeTvq(configurationState)} />
          </div>
        </div>
        <Row
          xs="auto"
          className="bg-light py-2 align-items-center justify-content-between"
        >
          <Col>{t('summary.total')}</Col>
          <Col className="price_fs fw-bold">
            <PriceFormatter
              price={vehicleFinalTotalPrice(configurationState, tps, tvq)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
