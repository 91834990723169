import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Header from "../layouts/Header";
import NavBar from "../layouts/NavBar";
import Footer from "../layouts/Footer";
import VehiclesTrimPage from "../pages/VehiclesTrimPage";
import VehiclePage from "../pages/VehiclePage";
import VehicleSummary from "../pages/VehicleSummary";
import VehiclesModelPage from "../pages/VehiclesModelPage";
import ConfirmationPage from "../pages/ConfirmationPage";

function EmptyComponent() {
  return null;
}

export default function AppRoutes() {
  const { t } = useTranslation();

  return (
    <div className="app">
      <Routes>
        <Route
          path="/:parentUrl/:make/"
          element={[
            <Header
              key="header"
              fluid={true}
              title={t("header.model")}
            />,
            <NavBar key="navbar" active="model" />,
            <VehiclesModelPage key="modelPage" />,
          ]}
        />
        <Route
          path="/:parentUrl/:make/:model"
          element={[
            <Header
              key="header"
              fluid={true}
              title={t("header.trim")}
            />,
            <NavBar key="navbar" active="trim" />,
            <VehiclesTrimPage key="trimPage" />,
          ]}
        />
        <Route
          path="/:parentUrl/:make/:model/:trim"
          element={[
            <Header key="header" fluid={true} title={t("header.config")} />,
            <NavBar key="navbar" active="configuration" />,
            <VehiclePage key="vehiclePage" />,
            <Footer key="footer" />,
          ]}
        />
        <Route
          path="/:parentUrl/:make/:model/:trim/protection"
          element={<EmptyComponent key="empty" />}
        />
        <Route
          path="/:parentUrl/:make/:model/:trim/summary"
          element={[
            <Header key="header" fluid={true} title={t("header.summary")} />,
            <NavBar key="navbar" active="summary" />,
            <VehicleSummary key="vehicleSummaryPage" />,
            <Footer key="footer" />,
          ]}
        />
        <Route path="/confirmation" element={<ConfirmationPage />} />
      </Routes>
    </div>
  );
}
