import { useEffect, useContext, PropsWithChildren } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import PriceFormatter from "../../helpers/PriceFormater";

export interface AccessoryOptions {
  id?: string | number;
  price?: string | number;
}

export interface AccessoryOptionsAccordionProps {
  title: string;
  accessoryOptions: AccessoryOptions[];
  eventKey: string;
}
export default function AccesoriesOptionsAccordion({
  title,
  accessoryOptions,
  eventKey,
}: PropsWithChildren<AccessoryOptionsAccordionProps>) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );

  function handleChecked(i: any) {
    let accessoryArray = [...configurationState.accessoryOption];
    let checked = false;
    accessoryArray.forEach(function (c) {
      if (c[1] === i) {
        checked = true;
      }
    });
    return checked;
  }

  function accessoryTotal(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      setConfigurationState({
        ...configurationState,
        accessoryOption: [
          ...configurationState.accessoryOption,
          [
            event.target.value,
            event.target.attributes[5].value,
            event.target.attributes[6].value,
            event.target.attributes[7].value,
          ],
        ],
        accessoryOptionTotalPrice: [
          ...configurationState.accessoryOptionTotalPrice,
          event.target.value,
        ],
      });
    } else {
      const index = configurationState.accessoryOptionTotalPrice.indexOf(
        event.target.value
      );
      configurationState.accessoryOption.splice(index, 1);
      configurationState.accessoryOptionTotalPrice.splice(index, 1);
      setConfigurationState({
        ...configurationState,
        accessoryOption: [...configurationState.accessoryOption],
        accessoryOptionTotalPrice: [
          ...configurationState.accessoryOptionTotalPrice,
        ],
      });
    }
  }

  const cumulatedAccessoryPrice = () => {
    let counters = [...configurationState.accessoryOptionTotalPrice];
    let total = 0;
    counters.forEach(function (c) {
      let e = parseInt(c);
      total += e;
    });

    return total;
  };

  let totalAccessory = cumulatedAccessoryPrice();

  useEffect(() => {
    if (totalAccessory >= 0) {
      setConfigurationState((e: any) => ({
        ...e,
        accessoryCumulatedPrice: totalAccessory,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAccessory]);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        {accessoryOptions?.map((accessory: any, key: number) => (
          <Card
            as={`label`}
            htmlFor={`accessory-${key}`}
            key={key}
            className={`optionCard mb-3 ${
              handleChecked(
                `${lang === "fr" ? accessory.title_fr : accessory.title_en}`
              )
                ? "border-primary"
                : ""
            }`}
            role="button"
          >
            <Card.Body>
              <div className="d-flex">
                <input
                  id={`accessory-${key}`}
                  type="checkbox"
                  className="form-check-input me-3 flex-shrink-0"
                  readOnly // readOnly nessessary to handle the checked without onChange
                  name="accessory"
                  data-value={`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}
                  data-img={accessory.img_url}
                  data-desc={`${
                    lang === "fr"
                      ? accessory.description_fr
                      : accessory.description_en
                  }`}
                  value={accessory.price}
                  onChange={accessoryTotal}
                  checked={handleChecked(
                    `${lang === "fr" ? accessory.title_fr : accessory.title_en}`
                  )}
                />
                <div className="flex-grow-1">
                  <span>{`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}</span>
                  <div className="fs-4 text-primary text-nowrap">
                    <PriceFormatter price={accessory.price}/>
                  </div>
                </div>
                <img
                  className="ratio-4-3 fit-cover img-fluid"
                  src={accessory.img_url}
                  alt={`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}
                  height={60}
                  width={80}
                />
              </div>
            </Card.Body>
          </Card>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
